import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Loading from './components/Loading';
import ErrorBoundary from './components/ErrorBoundary';
import './styles/animations.css';

const Home = React.lazy(() => import('./pages/Home'));
const UserAcquisition = React.lazy(() => import('./pages/UserAcquisition'));
const Retargeting = React.lazy(() => import('./pages/Retargeting'));
const CaseStudy = React.lazy(() => import('./pages/CaseStudy'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <div className="app">
        <Header />
        <main>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/solutions/user-acquisition" element={<UserAcquisition />} />
              <Route path="/solutions/retargeting" element={<Retargeting />} />
              <Route path="/resources/case-study" element={<CaseStudy />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="*" element={<div>404 Not Found</div>} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default App;
  