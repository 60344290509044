import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

const Header: React.FC = () => {
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const location = useLocation();
  const currentPath = location.pathname;

  // 判断当前路径是否匹配
  const isActive = (path: string) => {
    if (path === '/') {
      return currentPath === path;
    }
    return currentPath.startsWith(path);
  };

  // 监听窗口大小变化
  useEffect(() => {
    const handleResize = () => {
      setIsMenuOpen(false);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 监听页面跳转，关闭菜单，关闭下拉菜单，根据路由设置title
  useEffect(() => {
    setIsMenuOpen(false);
    setIsSolutionsOpen(false);
    setIsResourcesOpen(false);
    // Set page title based on current path
    const getPageTitle = () => {
      switch (currentPath) {
        case '/':
          return 'Home | Orbit';
        case '/about':
          return 'About Us | Orbit';
        case '/solutions/user-acquisition':
          return 'User Acquisition | Orbit';
        case '/solutions/retargeting':
          return 'Retargeting | Orbit';
        case '/resources/case-study':
          return 'Case Study | Orbit';
        default:
          return 'Orbit';
      }
    };

    document.title = getPageTitle();
  }, [currentPath]);

  return (
    <header className="header">
      <div className="container-fluid">
        {/* PC端导航 */}
        <div className="desktop-nav">
          <Link to="/" className="logo">
            <img src="/images/orbit-logo.png" alt="Orbit" />
          </Link>
          <div className="nav-wrapper">
            <nav className="nav">
              <ul className="nav-list">
                <li>
                  <Link to="/" className={`nav-link ${isActive('/') ? 'active' : ''}`}>HOME</Link>
                </li>
                <li className="dropdown">
                  <span
                    className={`nav-link ${isActive('/solutions') ? 'active' : ''}`}
                    onMouseEnter={() => setIsSolutionsOpen(true)}
                    onMouseLeave={() => setIsSolutionsOpen(false)}
                  >
                    SOLUTIONS
                    <span className="arrow">▼</span>
                  </span>
                  {isSolutionsOpen && (
                    <ul
                      className="dropdown-menu"
                      onMouseEnter={() => setIsSolutionsOpen(true)}
                      onMouseLeave={() => setIsSolutionsOpen(false)}
                    >
                      <li>
                        <Link to="/solutions/user-acquisition" className={`dropdown-item ${isActive('/solutions/user-acquisition') ? 'active' : ''}`}>USER ACQUISITION</Link>
                      </li>
                      <li>
                        <Link to="/solutions/retargeting" className={`dropdown-item ${isActive('/solutions/retargeting') ? 'active' : ''}`}>RETARGETING</Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="dropdown">
                  <span
                    className={`nav-link ${isActive('/resources') ? 'active' : ''}`}
                    onMouseEnter={() => setIsResourcesOpen(true)}
                    onMouseLeave={() => setIsResourcesOpen(false)}
                  >
                    RESOURCES
                    <span className="arrow">▼</span>
                  </span>
                  {isResourcesOpen && (
                    <ul
                      className="dropdown-menu"
                      onMouseEnter={() => setIsResourcesOpen(true)}
                      onMouseLeave={() => setIsResourcesOpen(false)}
                    >
                      <li>
                        <Link to="/resources/case-study" className={`dropdown-item ${isActive('/resources/case-study') ? 'active' : ''}`}>CASE STUDY</Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <Link to="/about" className={`nav-link ${isActive('/about') ? 'active' : ''}`}>ABOUT US</Link>
                </li>
              </ul>
            </nav>
            <div className="language-selector">
              <img src="/images/en-flag.png" alt="English" className="flag-icon" />
              <span>English</span>
            </div>
          </div>
        </div>

        {/* 移动端导航 */}
        <div className="mobile-nav">
          <Link to="/" className="logo">
            <img src="/images/orbit-logo.png" alt="Orbit" />
          </Link>

          <button 
            className={`menu-toggle ${isMenuOpen ? 'active' : ''}`} 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>

          <nav className={`mobile-menu ${isMenuOpen ? 'active' : ''}`}>
            <ul className="mobile-nav-list">
              <li>
                <Link to="/" className="nav-link" onClick={() => setIsMenuOpen(!isMenuOpen)}>HOME</Link>
              </li>
              <li className={`mobile-dropdown ${isSolutionsOpen ? 'active' : ''}`}>
                <div 
                  className="nav-link"
                  onClick={() => setIsSolutionsOpen(!isSolutionsOpen)}
                >
                  SOLUTIONS
                  <span className="arrow">▼</span>
                </div>
                <ul className="mobile-dropdown-menu">
                  <li>
                    <Link 
                      to="/solutions/user-acquisition" 
                      className="dropdown-item"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      USER ACQUISITION
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/solutions/retargeting" 
                      className="dropdown-item"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      RETARGETING
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`mobile-dropdown ${isResourcesOpen ? 'active' : ''}`}>
                <div 
                  className="nav-link"
                  onClick={() => setIsResourcesOpen(!isResourcesOpen)}
                >
                  RESOURCES
                  <span className="arrow">▼</span>
                </div>
                <ul className="mobile-dropdown-menu">
                  <li>
                    <Link 
                      to="/resources/case-study" 
                      className="dropdown-item"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      CASE STUDY
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about" className="nav-link" onClick={() => setIsMenuOpen(!isMenuOpen)}>ABOUT US</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header; 